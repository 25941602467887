import styles from "./SkillsModal.module.scss";
import taskStyles from "./../../Screens/Tasks/Tasks.module.scss";
import React, { useState, useMemo, useCallback } from "react";
import clsx from "clsx";

import Loader from "../../Loader/Loader";
import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import ProgressBar from "../../base/ProgressBar/ProgressBar";
import Button from "../../base/Button/Button";

import { useApp } from "../../../context/AppProvider";
import useApi from "../../../api/rest";
import SkillList from "./SkillList/SkillList";
import Balance from "../../Balance/Balance";

import { formatAmount } from "./utils";

export const SkillsModal = ({ onClose }) => {
  const { userInfo, setUserInfo } = useApp();

  const {
    balance = 0,
    displaySkills = {},
    displayGameLevel = {},
    skills = {},
    gameLevel = 0,
    farmStats = {},
  } = userInfo || {};

  const { upgradeSkill, upgradeGameLevel } = useApi();
  const [message, setMessage] = useState({ show: false, text: "" });
  const [activeSkillGroup, setActiveSkillGroup] = useState("Personal");
  const [upgradingSkills, setUpgradingSkills] = useState({});
  const [isUpgradingGameLevel, setIsUpgradingGameLevel] = useState(false);
  const [confettiSkill, setConfettiSkill] = useState(null);
  const [confettiPosition, setConfettiPosition] = useState(null);

  const [totalDrumsPerDay] = useState(() => farmStats.bonusFarmPerDay || 0);

  const showMessageHandler = useCallback((text) => {
    setMessage({ show: true, text });
    setTimeout(() => setMessage({ show: false, text: "" }), 3000);
  }, []);

  const canUpgradeSkill = useCallback(
    (skill) => {
      return (
        skill.level < displayGameLevel.maxSkillLevel &&
        skill.level < 100 &&
        balance >= skill.nextLevelUpgradeCost
      );
    },
    [displayGameLevel.maxSkillLevel, balance],
  );

  const canUpgradeGameLevel = useCallback((skills, gameLevel) => {
    if (!skills || !gameLevel) {
      return false;
    }

    return Object.values(skills).every((skillLvl) => skillLvl >= gameLevel + 1);
  }, []);

  const filteredSkills = useMemo(() => {
    return Object.entries(displaySkills)
      .filter(([_, skill]) => skill.group === activeSkillGroup)
      .map(([skillId, skill]) => ({
        skillId,
        skill,
        canUpgrade: canUpgradeSkill(skill),
      }));
  }, [displaySkills, activeSkillGroup, canUpgradeSkill]);

  const getUpgradeButtonText = useCallback((skill) => {
    if (skill.level === 100) {
      return "Max Level";
    }
    return `Upgrade Lv.${skill.level + 1}`;
  }, []);

  const handleUpgradeSkill = useCallback(
    async (skillId, skill, position) => {
      if (!canUpgradeSkill(skill)) {
        if (skill.level >= displayGameLevel.maxSkillLevel) {
          showMessageHandler(
            `To make an upgrade you need to level up your overall level to ${skill.nextSkillRequiredGameLevel}`,
          );
        } else if (balance < skill.nextLevelUpgradeCost) {
          showMessageHandler("Insufficient balance to upgrade this skill");
        }
        return;
      }

      setUpgradingSkills((prev) => ({ ...prev, [skillId]: true }));

      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        const response = await upgradeSkill(skillId);

        if (response.status === "error") {
          showMessageHandler(
            `An error occurred while upgrading the skill. ${response.error?.response?.data?.data?.reason}`,
          );
          return;
        }

        const {
          displaySkills: updatedSkills,
          skills: updatedSkillsLevels,
          balance: newBalance,
          spentDrums,
          farmStats: updatedFarmStats,
        } = response;

        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          displaySkills: { ...prevUserInfo.displaySkills, ...updatedSkills },
          skills: { ...prevUserInfo.skills, ...updatedSkillsLevels },
          balance: newBalance,
          spentDrums,
          farmStats: updatedFarmStats,
        }));

        if (position) {
          setConfettiPosition(position);
          setConfettiSkill(skillId);
          setTimeout(() => setConfettiSkill(null), 3000);
        }
      } catch (error) {
        console.error("Failed to upgrade skill:", error);
        showMessageHandler("An error occurred while upgrading the skill");
      } finally {
        setUpgradingSkills((prev) => {
          const newState = { ...prev };
          delete newState[skillId];
          return newState;
        });
      }
    },
    [
      canUpgradeSkill,
      displayGameLevel,
      balance,
      upgradeSkill,
      showMessageHandler,
      setUserInfo,
    ],
  );

  const handleUpgradeGameLevel = async () => {
    if (canUpgradeGameLevelNow) {
      try {
        setIsUpgradingGameLevel(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        const response = await upgradeGameLevel();
        if (response.status === "error") {
          return showMessageHandler(
            `An error occurred while upgrading the game level ${response.error?.response?.data?.data?.reason}`,
          );
        }
        const {
          gameLevel: updatedGameLevel,
          displayGameLevel: updatedDisplayGameLevel,
          balance,
        } = response;

        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          gameLevel: updatedGameLevel,
          displayGameLevel: updatedDisplayGameLevel,
          balance,
        }));
      } catch (error) {
        showMessageHandler("An error occurred while upgrading the game level");
      } finally {
        setIsUpgradingGameLevel(false);
      }
    } else {
      showMessageHandler(
        `To upgrade a level all your Personal and Ceremonial skills must be the ${
          gameLevel + 1
        } level or above`,
      );
    }
  };

  const canUpgradeGameLevelNow = useMemo(
    () => canUpgradeGameLevel(skills, gameLevel),
    [skills, gameLevel, canUpgradeGameLevel],
  );

  return (
    <ModalWrapper
      onClose={onClose}
      className={styles.skillsModal}
      header={
        <span className={styles.bonus}>
          <Text variant="textM" weight="bold">
            +{formatAmount(totalDrumsPerDay)} Drums for Farm
          </Text>
        </span>
      }
    >
      {/* Game Level Info */}
      <div className={styles.gameLevelInfo}>
        <Text
          variant="h1"
          weight="bold"
          className={styles.gameLevelTitle}
          color="gradient"
        >
          {`${displayGameLevel?.group || ""} ${displayGameLevel?.level || ""}`}
        </Text>

        <ProgressBar
          level={displayGameLevel?.level || 0}
          maxLevel={100}
          className={styles.gameLevelBar}
        />
      </div>

      {/* Drum balance */}
      <Balance balance={balance} className={styles.balance} />

      {/* Upgrade section */}
      <div className={styles.upgradeSection}>
        <Button
          variant="filledSec"
          onClick={handleUpgradeGameLevel}
          onDisabledClick={() => {
            showMessageHandler(
              "To upgrade a level all your Personal and Ceremonial skills must be the same level or above",
            );
          }}
          className={clsx(styles.upgradeGameLevelButton, {
            [styles.loading]: isUpgradingGameLevel,
          })}
          ariaDisabled={!canUpgradeGameLevelNow}
        >
          {isUpgradingGameLevel ? (
            <Loader size="small" className={styles.buttonLoader} />
          ) : (
            <div className={styles.buttonContent}>
              <Text variant="textL" weight="bold">
                {canUpgradeGameLevelNow ? "Upgrade" : "Level up to Upgrade"}
              </Text>
              <span className={styles.rewardText}>
                <Text variant="textS" weight="semiBold">
                  {canUpgradeGameLevelNow
                    ? `Get a reward ${displayGameLevel.reward || 0} DRUMs`
                    : `To get a reward ${displayGameLevel.reward || 0} DRUMs`}
                </Text>
              </span>
            </div>
          )}
        </Button>

        {canUpgradeGameLevelNow && (
          <Text
            color="gradient"
            variant="textS"
            weight="semiBold"
            className={styles.freeUpgradeText}
          >
            Upgrade level for free
          </Text>
        )}
      </div>

      {/* Skills toggle section */}
      <button
        type="button"
        onClick={() =>
          setActiveSkillGroup((prev) =>
            prev === "Personal" ? "Ceremonial" : "Personal",
          )
        }
        className={clsx(taskStyles.toggleElement, styles.skillGroup)}
      >
        <span
          className={clsx(
            taskStyles.toggleLabel,
            activeSkillGroup === "Personal" ? taskStyles.toggleActive : "",
          )}
        >
          Personal
        </span>
        <span
          className={clsx(
            taskStyles.toggleLabel,
            activeSkillGroup === "Ceremonial" ? taskStyles.toggleActive : "",
          )}
        >
          Ceremonial
        </span>
      </button>

      {/* Skills list */}
      <SkillList
        filteredSkills={filteredSkills}
        onUpgrade={handleUpgradeSkill}
        upgradingSkills={upgradingSkills}
        getUpgradeButtonText={getUpgradeButtonText}
        confettiSkill={confettiSkill}
        confettiPosition={confettiPosition}
      />
      {/* Popup message */}
      {message.show && (
        <div className={styles.popupMessage}>
          {
            <Text variant="textXM" weight="bold">
              {message.text}
            </Text>
          }
        </div>
      )}
    </ModalWrapper>
  );
};

export const SkillsModalContext = React.createContext(null);

export const SkillsModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalUserInfo, setModalUserInfo] = React.useState(null);

  const handleModalState = (val, userInfo = null) => {
    setIsOpen(val);
    if (val) {
      setModalUserInfo(userInfo);
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <SkillsModalContext.Provider
      value={{ isOpen, setIsOpen: handleModalState }}
    >
      {children}
      {isOpen && (
        <SkillsModal
          userInfo={modalUserInfo}
          onClose={() => handleModalState(false)}
        />
      )}
    </SkillsModalContext.Provider>
  );
};
