import styles from "./Router.module.scss";
import { useApp } from "../../context/AppProvider";
import Navigation from "../Navigation/Navigation";
import Farm from "../Screens/Farm";
import Tasks from "../Screens/Tasks";
import Team from "../Screens/Team";
import Email from "../Screens/Email/Email";
import Tap from "../Screens/Tap/Tap";
import clsx from "clsx";
import SuccessModal from "../Modals/SucessModal/SuccessModal";
import ConfettiExplosion from "react-confetti-explosion";
import { useEffect, useState } from "react";
import QrCode from "../Screens/QrCode/QrCode";
import { SkillsModalProvider } from "../Modals/SkillsModal/SkillsModal";
import { DrumsModalProvider } from "../Modals/DrumsModal/DrumsModal";
import { FaqModalProvider } from "../Modals/FaqModal/FaqModal";

const Router = () => {
  const { showScreen, showSuccessModal } = useApp();
  const [isExploding, setIsExploding] = useState(false);

  const confettiProps = {
    force: 0.6,
    duration: 2500,
    particleCount: 80,
    width: 1000,
    zIndex: 40,
    colors: ["#ffffff", "#5CE605", "#41A105", "#FFD600", "#FF6B00"],
  };

  useEffect(() => {
    if (showSuccessModal) {
      setIsExploding(true);
      setTimeout(() => {
        setIsExploding(false);
      }, 3000);
    } else {
      setIsExploding(false);
    }
  }, [showSuccessModal]);

  return (
    <div className={clsx(styles.container, styles[showScreen])}>
      {isExploding && (
        <div className={styles.confettiContainer}>
          <ConfettiExplosion {...confettiProps} />
        </div>
      )}
      {showScreen === "email" ? (
        <div className={styles.wrapper}>
          <Email />
        </div>
      ) : showScreen === "code" ? (
        <div className={styles.wrapper}>
          <QrCode />
        </div>
      ) : (
        <FaqModalProvider>
          <SkillsModalProvider>
            <DrumsModalProvider>
              {/* <UpgradeModalProvider> */}
              <div className={styles.wrapper}>
                {showScreen === "tap" && <Tap />}
                {showScreen === "farm" && <Farm />}
                {showScreen === "tasks" && <Tasks />}
                {showScreen === "team" && <Team />}
              </div>
              <Navigation />
              <SuccessModal />
              {/* </UpgradeModalProvider> */}
            </DrumsModalProvider>
          </SkillsModalProvider>
        </FaqModalProvider>

        // <UpgradeModalProvider>
        //   <div className={styles.wrapper}>
        // {showScreen === "tap" && <Tap />}
        // {showScreen === "farm" && <Farm />}
        // {showScreen === "tasks" && <Tasks />}
        // {showScreen === "team" && <Team />}
        //   </div>
        //   <Navigation />
        //   <SuccessModal />
        // </UpgradeModalProvider>
      )}
    </div>
  );
};

export default Router;
