import React, { useEffect, useState, useCallback, useMemo } from "react";
import Text from "../../base/Text/Text";
import { useApp } from "../../../context/AppProvider";
import useApi from "../../../api/rest";
import { TOKEN_NAME } from "../../../constants";
import { useTelegram } from "../../../context/TelegramProvider";
import styles from "./Tasks.module.scss";
import { formatNumberForUi } from "../../../utils/strings";
import Task from "./components/Task";
import RegisterTask from "./components/RegisterTask";
import clsx from "clsx";

const Tasks = () => {
  const { webApp } = useTelegram();
  const {
    userInfo = {},
    setUserInfo,
    setShowSuccessModal,
    setSuccessModalData,
  } = useApp();
  const { startTask, claimTask, checkTask } = useApi();
  const [tasks, setTasks] = useState({ inProgress: [], completed: [] });
  const [startTaskLoading, setStartTaskLoading] = useState(null);
  const [claimTaskLoading, setClaimTaskLoading] = useState(false);
  const [checkTaskLoading, setCheckTaskLoading] = useState(null);
  const [showCompleted, setShowCompleted] = useState(false);
  const [message, setMessage] = useState({ show: false, text: "" });

  const showMessageHandler = useCallback((text) => {
    setMessage({ show: true, text });
    setTimeout(() => setMessage({ show: false, text: "" }), 3000);
  }, []);

  const handleGoTask = useCallback(
    (data) => {
      const { taskDetails: details, type, inviteUrl } = data;

      if (type === "referral" && webApp) {
        if (webApp.platform === "ios") {
          try {
            webApp.openTelegramLink(inviteUrl);
          } catch (error) {
            console.error("handleGoTask error: ", error, { inviteUrl });
            window.open(inviteUrl, "_blank");
          }
        } else {
          window.open(inviteUrl, "_blank");
        }
      }

      if (type === "social" && details?.action === "redirect" && webApp) {
        if (webApp.platform === "ios") {
          if (details.redirectUrl?.includes("t.me")) {
            try {
              webApp.openTelegramLink(details.redirectUrl);
            } catch (error) {
              console.error("handleGoTask error: ", error, {
                redirectUrl: details.redirectUrl,
              });
            }
          } else {
            webApp.openLink(details.redirectUrl);
          }
        } else {
          window.open(details.redirectUrl, "_blank");
        }
      }
    },
    [webApp],
  );

  const handleStartTask = useCallback(
    (task) => {
      setStartTaskLoading(task.taskId);
      startTask(task.taskId)
        .then((data) => {
          if (data.status === "error") {
            const reason =
              data.error?.response?.data?.data?.reason ?? data.reason ?? "";
            const reasonMessage = reason ? `: ${reason}` : "";
            showMessageHandler("Failed to start task" + reasonMessage);
            return;
          }

          setTimeout(() => {
            const updatedTasks = userInfo?.tasks ?? {};
            updatedTasks[task.taskId] = data;

            setUserInfo({
              ...userInfo,
              tasks: updatedTasks,
            });
            setStartTaskLoading(null);

            if (data.taskDetails) {
              handleGoTask(task.taskData);
            }
          }, 1000);
        })
        .catch((error) => {
          console.error("startTask error: ", error);

          showMessageHandler(
            `An error occurred while starting the task` + error.message ?? "",
          );
        })
        .finally(() => {
          setStartTaskLoading(null);
        });
    },
    [startTask, setUserInfo, handleGoTask, userInfo, showMessageHandler],
  );

  const handleCheckTask = useCallback(
    (taskId) => {
      setCheckTaskLoading(taskId);
      checkTask(taskId)
        .then((data) => {
          if (data.status === "error") {
            const reason =
              data.error?.response?.data?.data?.reason ?? data.reason ?? "";
            const reasonMessage = reason ? `: ${reason}` : "";
            console.error("checkTask error: ", data);
            showMessageHandler(`Failed to check task` + reasonMessage);
            return;
          }

          setTimeout(() => {
            const updatedTasks = userInfo?.tasks ?? {};
            updatedTasks[taskId] = data;

            setUserInfo({
              ...userInfo,
              tasks: updatedTasks,
            });
          }, 1000);
        })
        .catch((error) => {
          console.error("checkTask error: ", error);

          showMessageHandler(
            `An error occurred while checking the task`,
            error.message ?? "",
          );
        })
        .finally(() => {
          setCheckTaskLoading(null);
        });
    },
    [checkTask, userInfo, showMessageHandler, setUserInfo],
  );

  const handleClaim = useCallback(
    (taskId) => {
      setClaimTaskLoading(taskId);
      claimTask(taskId)
        .then((data) => {
          if (data.status === "error") {
            const reason =
              data.error?.response?.data?.data?.reason ?? data.reason ?? "";
            const reasonMessage = reason ? `: ${reason}` : "";
            showMessageHandler("Failed to claim task" + reasonMessage);
            return;
          }

          const updatedTasks = userInfo?.tasks ?? {};
          updatedTasks[taskId] = data.task;

          setUserInfo({
            ...userInfo,
            balance: data.balance,
            tasks: updatedTasks,
          });

          setShowSuccessModal(true);
          setSuccessModalData({
            reward: (
              <Text variant="h1" weight="semiBold">
                +{formatNumberForUi(data.claimedBalance)}{" "}
                <Text variant="h1" weight="semiBold" color="gradient">
                  {TOKEN_NAME}
                </Text>
              </Text>
            ),
            description: (
              <Text variant="textL">
                Stay bright, keep the fight,
                <br />
                In every challenge, find the light.
                <br />
                Earn more {TOKEN_NAME}!
              </Text>
            ),
          });
        })
        .catch((error) => {
          console.error("claimTask error: ", error);

          showMessageHandler(
            "An error occurred while claiming the task" + error.message ?? "",
          );
        })
        .finally(() => {
          setClaimTaskLoading(null);
        });
    },
    [
      claimTask,
      setUserInfo,
      setShowSuccessModal,
      setSuccessModalData,
      userInfo,
      showMessageHandler,
    ],
  );

  useEffect(() => {
    if (userInfo?.tasks) {
      const taskInProgressPre = [];
      const taskDonePre = [];

      Object.entries(userInfo.tasks).forEach(([key, task]) => {
        const taskData = {
          ...(task.type === "referral" && { inviteUrl: userInfo.inviteUrl }),
          ...task,
        };

        if (task.state === "Claimed") {
          taskDonePre.push({ taskId: key, taskData });
        } else {
          taskInProgressPre.push({ taskId: key, taskData });
        }
      });

      const sortedTasks = (tasks) =>
        SORT.reduce((acc, el) => {
          const task = tasks.find((t) => t.taskId === el);
          if (task) acc.push(task);
          return acc;
        }, []);

      setTasks({
        inProgress: sortedTasks(taskInProgressPre),
        completed: sortedTasks(taskDonePre),
      });
    }
  }, [userInfo]);

  const renderTask = useCallback(
    (task, index) => {
      const commonProps = {
        task,
        handleStartTask,
        startTaskLoading,
        handleGoTask,
        handleClaim,
        claimTaskLoading,
        handleCheckTask,
        checkTaskLoading,
      };

      if (task.taskId === "register_on_cex_io") {
        return (
          <RegisterTask
            key={task.taskId}
            {...commonProps}
            userData={{
              email: userInfo.email,
              sentEmail: userInfo?.sentEmail,
              sentEmailCreatedAtFormatted:
                userInfo?.sentEmailCreatedAtFormatted,
            }}
          />
        );
      }
      return <Task key={task.taskId} {...commonProps} />;
    },
    [
      handleStartTask,
      startTaskLoading,
      handleGoTask,
      handleClaim,
      claimTaskLoading,
      handleCheckTask,
      checkTaskLoading,
      userInfo,
    ],
  );

  const tasksToRender = useMemo(
    () => (showCompleted ? tasks.completed : tasks.inProgress),
    [showCompleted, tasks],
  );

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Text tag="p" variant="h2" weight="bold">
          Achieve the task,
          <br />
          earn the tribal reward!
        </Text>
        <Text tag="p" variant="textXL" weight="bold" color="gradient">
          But keep in mind, only qualified actions
          <br />
          unlock {TOKEN_NAME} tokens! ✨
        </Text>
      </div>
      <button
        type="button"
        onClick={() => setShowCompleted((prev) => !prev)}
        className={clsx(styles.toggleElement)}
      >
        <span
          className={clsx(
            styles.toggleLabel,
            !showCompleted && styles.toggleActive,
          )}
        >
          New
        </span>
        <span
          className={clsx(
            styles.toggleLabel,
            showCompleted && styles.toggleActive,
          )}
        >
          Completed
        </span>
      </button>
      <ul className={styles.taskList}>{tasksToRender.map(renderTask)}</ul>
      {message.show && (
        <div className={styles.popupMessage}>
          <Text variant="textXM" weight="bold">
            {message.text}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Tasks;

const SORT = [
  "subscribe_telegram",
  "follow_on_x",
  "share_tweet_on_x",
  "share_tweet_on_x_2",
  "share_post_on_facebook_2",
  "join_discord",
  "join_reddit",
  "follow_on_youtube",
  "boost_telegram_1",
  "install_wigwam_extension",
  "share_post_on_facebook",
  "invite_1_friend",
  "invite_5_friends",
  "invite_10_friends",
  "invite_20_friends",
  "invite_50_friends",
  "invite_100_friends",
  "join_cex_io",
  "share_tweet_on_twitter",
  "join_linkedin",
  "join_facebook",
  "review_chrome_webstore",
];
