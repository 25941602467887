import styles from "./FaqModal.module.scss";

import React, { useState } from "react";
import parse from "html-react-parser";

import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import Icon from "../../Icon/Icon";

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const parsedAnswer = parse(answer, {
    replace: (domNode) => {
      if (domNode.name === "a" && domNode.attribs) {
        return (
          <a
            href={domNode.attribs.href}
            target={domNode.attribs.target}
            rel={domNode.attribs.rel}
          >
            {domNode.children[0].data}
          </a>
        );
      }
    },
  });

  return (
    <div className={styles.faqItem}>
      <div className={styles.textContainer}>
        <div className={styles.faqQuestion}>
          <Text variant="textL" weight="bold" align="left">
            {question}
          </Text>
        </div>
        <div className={`${styles.faqAnswer} ${isOpen ? styles.open : ""}`}>
          <Text variant="textXM" weight="semiBold" color="gradient">
            {parsedAnswer}
          </Text>
        </div>
      </div>
      <div className={styles.iconWrapper} onClick={() => setIsOpen(!isOpen)}>
        <Icon
          width={24}
          height={24}
          name={isOpen ? "chevronUp" : "chevronDown"}
        />
      </div>
    </div>
  );
};

const makeHyperlink = (text, link) => {
  return `<a href="${link}" target="_blank" rel="noopener noreferrer">${text}</a>`;
};
export const FaqModal = ({ onClose }) => {
  const faqData = [
    {
      question: "What is the game about?",
      answer:
        "The Wigwam Drum Game was originally designed as a fun experience for <a href='https://wigwam.app' target='_blank' rel='noopener noreferrer'>Wigwam Wallet</a> users. Due to new partnerships and rapid growth, reaching over 5 million users in just two months, we decided to expand the game and offer future rewards to all active players.",
    },
    {
      question: "How to play this game: Rewards Skills, Levels, Farms?",
      answer: `Your goal is to earn as many DRUM points as possible through various fun activities.
<br />
Here’s how you can get started:
 <br />
 <br />
<b>Main Game Activities</b>
<br />
<br />
You can earn <b>DRUM</b> points by engaging in four key activities:
<br />
<br />
1) <b>Tap a Drum</b>: Tap your drum to earn points. Every tap adds DRUMs to your balance. Upgrade your drum to get more tokens.
<br />
<br />
2) <b>Farm Tokens</b> in the Farm Section: Farm DRUM points over time in this section and increase rewards by upgrading your skills.
<br />
<br />
3) <b>Invite Friends</b>: Bring your friends to the game and earn a percentage of the DRUM points they collect. Also, you get <b>bonus taps</b> for your drum.
<br />
<br />
4) <b>Complete Rewarding Tasks in the Target Section</b>: Earn DRUMs by completing a variety of tasks such as social and promotional activities.
<br />
<br />
To increase the number of DRUMs you should also update your <b>Skills</b> and a <b>Game level</b>. You may read ${makeHyperlink(
        "this detailed tutorial",
        "https://wigwam.crunch.help/en/drum-game/wigwam-drum-game-tutorial",
      )} about all gaming mechanics.
`,
    },
    {
      question: "What are your product plans?",
      answer:
        "We aim to continue developing the game over the next 7-9 months. Once the game is complete, rewards will be distributed to players. However, depending on marketing conditions, we may conclude the game earlier to reward our community sooner. For the latest game status and news, visit our <a href='https://t.me/wigwam_announcements' target='_blank' rel='noopener noreferrer'>Telegram channel</a>.",
    },
    {
      question: "When is the Token Generation Event (TGE)?",
      answer:
        "TGE is one option we are considering, but we may also reward players with popular crypto tokens such as USDT or ETH, or distribute new tokens from our partners. The exact date for the reward distribution event will be announced after the game is complete. Follow <a href='https://t.me/wigwam_announcements' target='_blank' rel='noopener noreferrer'>our updates</a> for the latest information.",
    },
    {
      question:
        "How will you calculate reward distribution? Should I spend or accumulate DRUMs?",
      answer:
        "We will calculate the final reward based on the total DRUMs you spent on upgrades during the game, plus the DRUMs remaining in your balance. Therefore, it is beneficial to spend your DRUMs on upgrades, as this will increase your rewards in the final calculation.",
    },
    {
      question: "Can I get some rewards now?",
      answer:
        "Yes! We frequently hold rewarding events in our <a href='https://t.me/wigwam_announcements' target='_blank' rel='noopener noreferrer'>Telegram channel</a>, where we distribute USDT and other prizes to our followers. Join our networks and check out our current promotional activities.",
    },
    {
      question: "What can I get with DRUMs?",
      answer:
        "DRUMs are the in-game currency used for upgrades and other activities. The more DRUMs you collect, the higher your level and the more rewards you can potentially earn.",
    },
    {
      question: "Why can't I connect my wallet and withdraw tokens?",
      answer:
        "The Wigwam Drum Game doesn't support blockchain, and DRUM is an in-game token, not a cryptocurrency. You cannot connect a wallet to withdraw these tokens. Wait for our official updates on reward distribution and how tokens will be claimed. If we launch a token, wallet integration will be added to the game.",
    },
    {
      question:
        "Why should I play your game when there are so many alternatives?",
      answer:
        "We don't promise millions in rewards like some projects do. What we can offer is a real product with community-focused goals, and we plan to reward active players. It's entirely up to you whether you choose to participate or not.",
    },
    {
      question: "I don't plan to play the game anymore.",
      answer:
        "If you're a current or former player, you can wait for our reward activities, where you can exchange collected tokens for rewards. If you decide to stop playing, we recommend keeping an eye on our announcements to avoid missing updates on rewards. We will also notify users via our bot, so avoid deleting it to stay informed.",
    },
    {
      question: "Where to find information about the team behind the project?",
      answer:
        "You can visit our <a href='https://wigwam.app/about' target='_blank' rel='noopener noreferrer'>website</a> to learn more about our team. If you have additional questions, feel free to ask them in our <a href='https://t.me/wigwamapp' target='_blank' rel='noopener noreferrer'>support chat</a>.",
    },
  ];

  return (
    <ModalWrapper
      onClose={onClose}
      className={styles.faqModal}
      headerClassName={styles.header}
      header={<br></br>}
    >
      <span className={styles.title}>
        <Text variant="h1" weight="bold" color="gradient">
          FAQ
        </Text>
      </span>
      <div className={styles.faqContent}>
        {faqData.map((item, index) => (
          <FaqItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
    </ModalWrapper>
  );
};

export const FaqModalContext = React.createContext(null);

export const FaqModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleModalState = (val) => {
    setIsOpen(val);
    if (val) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <FaqModalContext.Provider value={{ isOpen, setIsOpen: handleModalState }}>
      {children}
      {isOpen && <FaqModal onClose={() => handleModalState(false)} />}
    </FaqModalContext.Provider>
  );
};
