import React, { useCallback, useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import useApi from "../api/rest";
import Text from "./base/Text/Text";
import styles from "./ErrorBoundary.module.scss";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div
      className={styles.container}
      style={{
        padding: "5px 25px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        "::before": {
          height: "250px !important",
          bottom: "0",
          background:
            "linear-gradient(10deg, #18261C 10px, rgba(24, 38, 28, 0) 100%); !important",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginBottom: "-20px",
        }}
      >
        <img src="/img/brokenDrum.png" width="260px" alt="" />
      </div>
      <Text variant="h2" align="left">
        Oops! Something went wrong.
      </Text>
      <Text variant="textXl" color="gradient" tag="div" align="left">
        We've identified an issue with the game.
        <br />A report regarding the crash has been submitted to the Wigwam team
        for investigation.
        <br />
        <br />
        Please try again later.
      </Text>
    </div>
  );
};

const ErrorBoundaryWrapper = ({ children }) => {
  const { sendLog } = useApi();
  const logsRef = useRef([]);

  useEffect(() => {
    console.log("ErrorBoundaryWrapper mounted");
    const originalLog = console.log;
    const originalWarn = console.warn;
    const originalError = console.error;

    const captureLog =
      (type) =>
      (...args) => {
        logsRef.current.push({
          type,
          args,
          timestamp: new Date().toISOString(),
        });
        if (logsRef.current.length > 20) {
          logsRef.current.shift();
        }

        if (type === "log") return originalLog(...args);
        if (type === "warn") return originalWarn(...args);
        return originalError(...args);
      };

    console.log = captureLog("log");
    console.warn = captureLog("warn");
    console.error = captureLog("error");

    return () => {
      console.log = originalLog;
      console.warn = originalWarn;
      console.error = originalError;
    };
  }, []);

  const getPerformanceMetrics = useCallback(() => {
    if (window.performance) {
      const navigation = window.performance.getEntriesByType("navigation")[0];
      const paint = window.performance.getEntriesByType("paint");
      return {
        loadTime: navigation.loadEventEnd - navigation.startTime,
        domContentLoaded:
          navigation.domContentLoadedEventEnd - navigation.startTime,
        firstPaint: paint.find((entry) => entry.name === "first-paint")
          ?.startTime,
        firstContentfulPaint: paint.find(
          (entry) => entry.name === "first-contentful-paint",
        )?.startTime,
      };
    }
    return null;
  }, []);

  const getRecentNetworkCalls = useCallback(() => {
    if (window.performance) {
      return window.performance
        .getEntriesByType("resource")
        .slice(-10)
        .map((entry) => ({
          name: entry.name,
          duration: entry.duration,
          startTime: entry.startTime,
          initiatorType: entry.initiatorType,
        }));
    }
    return [];
  }, []);

  const getRecentConsoleLogs = useCallback(() => {
    return logsRef.current;
  }, []);

  const gatherAdditionalInfo = useCallback(
    (error) => {
      return {
        url: window.location.href,
        userAgent: navigator.userAgent,
        timestamp: new Date().toISOString(),
        performanceMetrics: getPerformanceMetrics(),
        recentNetworkCalls: getRecentNetworkCalls(),
        consoleLog: getRecentConsoleLogs(),
      };
    },
    [getPerformanceMetrics, getRecentNetworkCalls, getRecentConsoleLogs],
  );

  const shouldSendError = useCallback((error) => {
    const errorKey = `${error.message}|${error.stack}`;
    const now = Date.now();
    const errorCache = JSON.parse(localStorage.getItem("errorCache") || "{}");
    const lastSent = errorCache[errorKey];

    if (lastSent && now - lastSent < 600000) {
      // 15 minutes
      return false;
    }

    errorCache[errorKey] = now;
    localStorage.setItem("errorCache", JSON.stringify(errorCache));
    return true;
  }, []);

  const handleError = useCallback(
    (error, info) => {
      console.error("Caught an error:", error, info);

      if (shouldSendError(error)) {
        const errorInfo = {
          componentStack: info.componentStack,
          ...gatherAdditionalInfo(error),
        };
        sendLog({
          type: "Error Boundary",
          message: error.message,
          stack: error.stack,
          ...errorInfo,
        });
      }
    },
    [sendLog, gatherAdditionalInfo, shouldSendError],
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
