import styles from "./Tap.module.scss";
import { useRef, useContext, useState, useEffect, memo } from "react";
import { animated, useSpring } from "@react-spring/web";

import Text from "../../base/Text/Text";
import ShineTokenName from "../../base/ShineTokenName/ShineTokenName";
import Button from "../../base/Button/Button";
import Balance from "../../Balance/Balance";

import { Duration } from "luxon";
import { TOKEN_NAME } from "../../../constants";
import useApi from "../../../api/rest";
import { useApp } from "../../../context/AppProvider";
import Icon from "../../Icon/Icon";
import Loader from "../../Loader/Loader";
import clsx from "clsx";
import Arrows from "./Arrows";
import { useTelegram } from "../../../context/TelegramProvider";
import { vibrate } from "../../../utils/vibrate";
import { SkillsModalContext } from "../../Modals/SkillsModal/SkillsModal";
import {
  DrumsModalContext,
  useDrums,
} from "../../Modals/DrumsModal/DrumsModal";
import DrumIcon from "../../Modals/DrumsModal/DrumList/DrumIcon";
import { FaqModalContext } from "../../Modals/FaqModal/FaqModal";

import { useDrumSound } from "./hooks/useDrumSound";

const Tap = () => {
  const { webApp } = useTelegram();
  const timer = useRef(null);
  const drumRef = useRef(null);
  const {
    userInfo = null,
    setUserInfo,
    setShowSuccessModal,
    setSuccessModalData,
    tapsState,
    setTapsState,
    tapsTimeLeftInMillis,
  } = useApp();

  const { displayDrum } = useDrums();

  const { playDrum, resetPlaybackRate } = useDrumSound(
    userInfo?.drumId ?? null,
  );

  const { claimTaps } = useApi();
  const [isClaimStarted, setIsClaimStarted] = useState(false);
  const [animateTap, setAnimateTap] = useState(false);
  const [animateClaimContainer, setAnimateClaimContainer] = useState(false);
  const [circleWidth, setCircleWidth] = useState(0);
  const [soundOn, setSoundOn] = useState(true);
  const [vibbrationOn, setVibrationOn] = useState(true);
  const upgraded = userInfo?.drumId > 0;

  const { setIsOpen: setSkillsModalOpen } = useContext(SkillsModalContext);
  const { setIsOpen: setDrumModalOpen } = useContext(DrumsModalContext);
  const { setIsOpen: setFaqModalOpen } = useContext(FaqModalContext);

  const isDisabled = tapsState.tapsLeft === 0 || isClaimStarted;

  const selectedDrum = displayDrum;

  const resetAnimation = () => {
    const drum = drumRef.current;
    if (!drum) return;
    drum.style.animation = "none";
    drum.style.animation = null;
  };

  const _handleTap = () => {
    if (
      !(tapsState.currentTapWindowFinishIn && tapsState.tapsLeft === 0) &&
      soundOn
    ) {
      playDrum();
    }

    resetAnimation();

    setAnimateTap(true);

    if (vibbrationOn) {
      vibrate({ duration: 400, interval: 100, count: 1 });
    }

    const newTapsLeft = tapsState.tapsLeft - 1;
    const newTapsCount = tapsState.tapsCount + 1;
    let newAmount = tapsState.amount;

    if (newTapsLeft < 0) {
      setAnimateClaimContainer(true);
      setTimeout(() => {
        setAnimateClaimContainer(false);
      }, 400);
      return;
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (!displayDrum) {
      // Handle the case when displayDrum is undefined
      return;
    }

    const { tapRewardMin = 0, tapRewardMax = 0 } = displayDrum || {};

    const tapRewardAvg = Math.floor((tapRewardMin + tapRewardMax) / 2);

    if (upgraded) {
      if (circleWidth + 1 > 270) {
        createParticle(upgraded, tapRewardMax, "max");
        newAmount = tapsState.amount + tapRewardMax;
      } else if (circleWidth + 1 > 210) {
        createParticle(upgraded, tapRewardAvg, "mid");
        newAmount = tapsState.amount + tapRewardAvg;
      } else {
        createParticle(upgraded, tapRewardMin, "low");
        newAmount = tapsState.amount + tapRewardMin;
      }
    } else {
      createParticle(false, tapRewardMin);
      newAmount = tapsState.amount + tapRewardMin;
    }

    setCircleWidth((prevWidth) => {
      if (prevWidth < 180) {
        return 180;
      }
      if (prevWidth + 1 > 340) {
        return 340;
      }
      return (
        prevWidth +
        (prevWidth > 320 ? 1 : prevWidth > 300 ? 2 : prevWidth > 240 ? 4 : 10)
      );
    });

    setTapsState({
      ...tapsState,
      tapsLeft: newTapsLeft,
      tapsCount: newTapsCount,
      amount: newAmount,
    });

    timer.current = setTimeout(() => {
      setCircleWidth(0);
      if (soundOn) resetPlaybackRate();
    }, 1000);
  };

  const handleClaim = () => {
    if (Number(tapsState.tapsCount) <= 0 || Number(tapsState.amount) <= 0) {
      return;
    }

    setIsClaimStarted(true);
    claimTaps(tapsState.tapsCount, tapsState.amount)
      .then((response) => {
        if (response.status !== "error") {
          setTapsState({
            ...tapsState,
            tapsLeft: null,
            tapsCount: 0,
            amount: 0,
          });
          setUserInfo({
            ...userInfo,
            ...response,
          });
          setShowSuccessModal(true);
          setSuccessModalData({
            reward: (
              <Text variant="h1" weight="bold">
                +{tapsState.amount} <ShineTokenName />
              </Text>
            ),
            description: (
              <Text variant="textL">
                Stay bright, keep the fight,
                <br />
                In every challenge, find the light.
                <br />
                Earn more {TOKEN_NAME}!
              </Text>
            ),
          });
        }
      })
      .finally(() => {
        setIsClaimStarted(false);
      });
  };

  const handleInviteFriends = () => {
    if (userInfo?.inviteUrl) {
      try {
        webApp.openTelegramLink(userInfo.inviteUrl);
      } catch (e) {
        console.error(`handleInviteFriends: ${e}\n${userInfo.inviteUrl}`);
      }
    }
  };

  const handleShare = () => {
    if (userInfo?.shareDetails) {
      try {
        const shareData = {
          title: userInfo.shareDetails.text,
          text: userInfo.shareDetails.text,
        };

        if (webApp.platform !== "ios") {
          shareData.url = userInfo.shareDetails.url;
        }

        if (navigator?.share) {
          navigator
            .share(shareData)
            .then(() => {
              console.log("Content shared successfully");
            })
            .catch((error) => {
              console.error("Failed to share content:", error);
            });
        } else if (userInfo.inviteUrl) {
          webApp.openTelegramLink(userInfo.inviteUrl);
        }
      } catch (e) {
        console.error(`handleShare: ${e}\n${userInfo.inviteUrl}`);
      }
    }
  };

  const [style, api] = useSpring(() => ({
    from: {
      translate: [0, 0],
      rotate: 0,
    },
    config: {
      duration: 4,
    },
  }));

  useEffect(() => {
    if (animateClaimContainer) {
      api.start({
        to: [
          {
            translate: [1, 1],
            rotate: 0,
          },
          {
            translate: [-1, -2],
            rotate: -1,
          },
          {
            translate: [-3, 0],
            rotate: 1,
          },
          {
            translate: [3, 2],
            rotate: 0,
          },
          {
            translate: [1, -1],
            rotate: 1,
          },
          {
            translate: [-1, 2],
            rotate: -1,
          },
          {
            translate: [-3, 1],
            rotate: 0,
          },
          {
            translate: [3, 1],
            rotate: -1,
          },
          {
            translate: [-1, -1],
            rotate: 1,
          },
          {
            translate: [1, 2],
            rotate: 0,
          },
          {
            translate: [0, 0],
            rotate: 0,
          },
        ],
      });

      setAnimateClaimContainer(false);
    }
  }, [animateClaimContainer, api]);

  const hanldeSoundClick = () => {
    setSoundOn((prev) => {
      localStorage.setItem("SOUND", !prev);
      return !prev;
    });
  };
  const hanldeVibrationClick = () => {
    setVibrationOn((prev) => {
      localStorage.setItem("VIBRATION", !prev);
      return !prev;
    });
  };

  useEffect(() => {
    setSoundOn(localStorage.getItem("SOUND") === "false" ? false : true);
    setVibrationOn(
      localStorage.getItem("VIBRATION") === "false" ? false : true,
    );
  }, []);

  const loading = !userInfo || isObjectEmpty(userInfo);

  const handleSkillsClick = () => {
    setSkillsModalOpen(true, userInfo);
  };

  const handleDrumsClick = () => {
    setDrumModalOpen(true, userInfo);
  };

  return (
    <>
      <div id="no-user-select">
        <div className={styles.headerContainer}>
          {!loading ? (
            <>
              <div className={styles.levelInfo}>
                <Text variant="textL" weight="bold">
                  {`${userInfo?.displayGameLevel?.group} ${userInfo?.displayGameLevel?.level}`}
                </Text>
                <Text
                  variant="textM"
                  weight="semiBold"
                  color="gradient"
                  className={styles.gameLevelName}
                >
                  {userInfo?.displayGameLevel?.name}
                </Text>
              </div>
            </>
          ) : null}
          <Button
            variant="filledAccent"
            size="sizeS"
            className={styles.faqButton}
            onClick={() => setFaqModalOpen(true)}
          >
            <Text variant="textM" weight="semiBold">
              FAQ
            </Text>
          </Button>
        </div>
        <Balance balance={userInfo?.balance} />
        <div className={styles.tapContainer}>
          <div className={styles.tapContainerText}>
            <Text tag="div" variant="textL" weight="bold">
              Keep tapping your drum!
              <br />
              Nonstop fun, all day long!
            </Text>
            <Text variant="textM" tag="div" color="orange">
              ⏰ Take a breather when the timer refills,
              <br />
              then get back to the thrill 🥁!
            </Text>
          </div>

          <div id="tapBtnContainer" className={styles.tapBtnContainer}>
            {tapsState.currentTapWindowFinishIn && tapsState.tapsLeft === 0 ? (
              <Text
                tag="div"
                color="white"
                weight="bold"
                className={styles.counter}
              >
                {Duration.fromMillis(tapsTimeLeftInMillis).toFormat("hh:mm:ss")}
              </Text>
            ) : null}
            <Button
              variant="icon"
              size="size0"
              onClick={_handleTap}
              className={styles.tapButton}
            >
              {!loading && selectedDrum ? (
                <DrumIcon
                  ref={drumRef}
                  name={selectedDrum.name}
                  width="260"
                  height="251"
                  className={clsx(
                    styles.tapCoin,
                    tapsState.currentTapWindowFinishIn &&
                      tapsState.tapsLeft === 0
                      ? styles.tapCoinDisabled
                      : "",
                    animateTap &&
                      !(
                        tapsState.currentTapWindowFinishIn &&
                        tapsState.tapsLeft === 0
                      )
                      ? styles.animate
                      : "",
                  )}
                  onAnimationEnd={() => setAnimateTap(false)}
                />
              ) : null}
            </Button>
            {!isDisabled && !loading ? (
              <>
                <div
                  className={clsx(
                    styles.backCircle,
                    upgraded ? styles.backCircleUpgraded : "",
                  )}
                  style={{ width: circleWidth, height: circleWidth }}
                />
                <div
                  className={clsx(
                    styles.backShine,
                    upgraded ? styles.backShineUpgraded : "",
                  )}
                />
                <Arrows isRed={upgraded} className={styles.arrowLeft} />
                <Arrows isRed={upgraded} className={styles.arrowRight} />
              </>
            ) : null}
          </div>
          <div className={styles.infoWrapper}>
            {/* <!-- Left Actions (Sound and Vibration) --> */}
            <div className={styles.infoActions}>
              <button
                type="button"
                onClick={hanldeSoundClick}
                className={clsx(
                  styles.soundButton,
                  !soundOn ? styles.soundButtonOff : "",
                )}
              >
                <Icon
                  name={!soundOn ? "sound-off" : "sound-on"}
                  width={24}
                  height={24}
                  className={styles.buttonIcon}
                />
              </button>

              <button
                type="button"
                onClick={hanldeVibrationClick}
                className={clsx(
                  styles.soundButton,
                  !vibbrationOn ? styles.soundButtonOff : "",
                )}
              >
                <Icon
                  name={"vibration-on"}
                  width={24}
                  height={24}
                  className={styles.buttonIcon}
                />
              </button>
            </div>

            {/* <!-- Center Taps Info --> */}
            <div
              className={clsx(styles.tapInfoLeft, {
                [styles.tapInfoLeftActive]: tapsState.tapsLeft > 0,
              })}
            >
              <Text tag="div" variant="textL" color="white" weight="semiBold">
                {tapsState.tapsLeft ?? 0} taps left
              </Text>
            </div>

            {/* <!-- Right Actions (Skills and Drums) --> */}
            <div className={styles.infoActions}>
              <Button
                type="icon"
                variant="filledSecV3"
                size="size0"
                className={styles.skillsIcon}
                onClick={handleSkillsClick}
              >
                <Icon
                  name="skills"
                  width={16}
                  height={24}
                  className={styles.largeIcon}
                />
              </Button>
              <Button
                type="icon"
                variant="filledSecV3"
                size="size0"
                onClick={handleDrumsClick}
                className={styles.drumsIcon}
              >
                <Icon
                  name="drums"
                  width={24}
                  height={20}
                  className={styles.largeIcon}
                />
              </Button>
            </div>
          </div>
        </div>
        <animated.div
          className={clsx(
            styles.claimContainer,
            tapsState.tapsLeft === 0 && tapsState.amount !== 0
              ? styles.claimContainerBorder
              : "",
            tapsState.tapsLeft !== 0 && tapsState.amount === 0
              ? styles.claimContainerTap
              : "",
            tapsState.tapsLeft === 0 && tapsState.amount === 0
              ? styles.claimContainerInvite
              : "",
          )}
          style={style}
        >
          {tapsState.amount !== 0 ? (
            <>
              <div className={styles.balance}>
                <Text variant="h3" weight="bold">
                  {tapsState.amount}
                </Text>
                <Text variant="h3" color="gradient" weight="bold">
                  {TOKEN_NAME}
                </Text>
              </div>
              <Button
                variant={upgraded ? "filledBull" : "filledSec"}
                size="sizeLMinW"
                onClick={handleClaim}
                disabled={isClaimStarted}
              >
                {isClaimStarted && <Loader disabled={isClaimStarted} />}
                <Text variant="textXL" weight="bold">
                  Claim
                </Text>
              </Button>
            </>
          ) : tapsState.tapsLeft !== 0 ? (
            <Text variant="textXL">
              Start tapping to Claim{" "}
              <Text variant="textXL" weight="bold">
                {TOKEN_NAME}
              </Text>
            </Text>
          ) : (
            <>
              <Text variant="textM" align="left">
                Need more taps?
                <br />
                Expand your tribe!
              </Text>
              <Button
                variant="filledSec"
                className={styles.inviteButton}
                onClick={handleInviteFriends}
              >
                <Text variant="textXL" weight="bold">
                  Invite
                </Text>
              </Button>
              <Button className={styles.shareButton} onClick={handleShare}>
                <Icon name="share" width="20px" height="20px" color="#FFFFFF" />
              </Button>
            </>
          )}
        </animated.div>
      </div>
    </>
  );
};

export default memo(Tap);

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const createParticle = (upgraded = false, tap, weight) => {
  const parent = document.querySelector("#tapBtnContainer");
  if (!parent) return;
  const style = document.createElement("style");
  const animationID = "id" + Math.random().toString(16).slice(2);
  const initTranslateX = randomIntFromInterval(-70, 70);
  const initScale = randomIntFromInterval(80, 140);
  const finalScale = initScale - randomIntFromInterval(10, 60);
  style.innerHTML = `
    @keyframes ${animationID} {
      0% {
        transform: translate(${
          initTranslateX + "px, " + randomIntFromInterval(-20, 20) + "px"
        }) scale(${initScale / 100}) rotate(0deg);
        opacity: 1;
      }

      30% {
        opacity: 1;
      }

      100% {
        transform: translate(${
          initTranslateX +
          randomIntFromInterval(-10, 10) +
          "px, " +
          randomIntFromInterval(-120, -200) +
          "px"
        }) scale(${finalScale / 100}) rotate(${randomIntFromInterval(
    -40,
    40,
  )}deg);
        opacity: 0;
      }
    }
    `;
  document.head.appendChild(style);

  const particle = document.createElement("div");
  particle.classList.add(styles.particle);

  if (upgraded) {
    particle.classList.add(styles.particleUpgraded);

    if (weight === "mid") {
      particle.classList.add(styles.particleUpgradedMid);
    } else if (weight === "max") {
      particle.classList.add(styles.particleUpgradedHigh);
    }
  }

  particle.innerHTML = `+${tap}`;
  particle.style.animation = `${animationID} 1s ease-out`;
  parent.append(particle);
  particle.addEventListener("animationend", function () {
    particle.remove();
    style.remove();
  });
};

const isObjectEmpty = (objectName) => {
  return !objectName || Object.keys(objectName).length === 0;
};
