import React from "react";
import clsx from "clsx";
import styles from "./ModalWrapper.module.scss";
import Icon from "../../Icon/Icon";

const ModalWrapper = ({
  children,
  onClose,
  header,
  className,
  headerClassName,
  closeButtonClassName,
}) => {
  return (
    <div className={styles.modalBackground}>
      <div className={`${styles.modalContent} ${className}`}>
        <div className={clsx(styles.header, headerClassName)}>{header}</div>
        <div className={styles.modalBody}>{children}</div>
        <button
          type="button"
          onClick={onClose}
          className={clsx(styles.closeButton, closeButtonClassName)}
        >
          <Icon
            width={20}
            height={20}
            name="close"
            className={styles.closeIcon}
          />
        </button>
      </div>
    </div>
  );
};

export default ModalWrapper;
