import React, { useMemo, useCallback, useEffect, useState } from "react";
import styles from "./Farm.module.scss";
import clsx from "clsx";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";
import { useFarmScreen } from "./hooks";
import useApi from "../../../api/rest";
import { useApp } from "../../../context/AppProvider";
import { TOKEN_NAME } from "../../../constants";
import { formatNumberForUi } from "../../../utils/strings";
import ShineTokenName from "../../base/ShineTokenName/ShineTokenName";
import { formatAmount } from "./../../Modals/SkillsModal/utils";

const Farm = () => {
  const {
    userInfo,
    setUserInfo,
    isClaimFarmStarted,
    setIsClaimFarmStarted,
    setShowSuccessModal,
    setSuccessModalData,
  } = useApp();

  const { startFarm, claimFarm } = useApi();
  const data = useFarmScreen();

  const farmDuration = 4;

  const [message, setMessage] = useState({ show: false, text: "" });
  const showMessageHandler = useCallback((text) => {
    setMessage({ show: true, text });
    setTimeout(() => setMessage({ show: false, text: "" }), 3000);
  }, []);

  const {
    baseFarm,
    baseFarmPerDay,
    bonusFarmPerDay,
    baseBonusFarm,
    buttonState,
    isStarted,
    displayLvlInfo,
  } = useMemo(() => {
    const { displayGameLevel = {}, farmStats = {} } = userInfo || {};
    const baseFarm = farmStats.baseFarm ?? "N/A";
    const baseFarmPerDay = farmStats.baseFarmPerDay ?? "N/A";
    const bonusFarmPerDay = farmStats.bonusFarmPerDay ?? "N/A";
    const baseBonusFarm = farmStats.farmBonus ?? "N/A";

    const buttonState = isClaimFarmStarted
      ? data.isClaimAllowed
        ? "claim"
        : "farming"
      : "start";

    const isStarted = buttonState !== "start";
    const displayLvlInfo = `${displayGameLevel?.group ?? ""} ${
      displayGameLevel?.level ?? ""
    }`.trim();

    return {
      displayGameLevel,
      farmStats,
      baseFarm,
      baseFarmPerDay,
      bonusFarmPerDay,
      baseBonusFarm,
      buttonState,
      isStarted,
      displayLvlInfo,
    };
  }, [userInfo, isClaimFarmStarted, data.isClaimAllowed]);

  useEffect(() => {
    if (data?.farmStartedAt) {
      setIsClaimFarmStarted(true);
    }
  }, [data?.farmStartedAt, setIsClaimFarmStarted]);

  const handleStartFarm = useCallback(() => {
    startFarm().then((data) => {
      if (data.status === "error") {
        showMessageHandler(
          `Failed to start farm: ${
            data?.error.response?.data?.data?.reason ?? ""
          }`,
        );
        return;
      }

      const { farmReward, farmStartedAt } = data?.data || {};

      if (farmReward !== undefined && farmStartedAt) {
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          farmReward,
          farmStartedAt,
        }));
      }
    });
  }, [startFarm, setUserInfo, showMessageHandler]);

  const handleClaimFarm = useCallback(() => {
    claimFarm()
      .then((data) => {
        const { balance, claimedBalance } = data?.data || {};

        if (balance !== undefined) {
          setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            farmReward: 0,
            farmStartedAt: undefined,
            balance,
          }));

          setShowSuccessModal(true);
          setSuccessModalData({
            reward: (
              <Text variant="h1" weight="bold">
                +{formatNumberForUi(claimedBalance ?? 0)} <ShineTokenName />
              </Text>
            ),
            description: (
              <Text variant="textL">
                Stay bright, keep the fight,
                <br />
                In every challenge, find the light.
                <br />
                Earn more {TOKEN_NAME}!
              </Text>
            ),
          });
        }
      })
      .finally(() => {
        setIsClaimFarmStarted(false);
      });
  }, [
    claimFarm,
    setUserInfo,
    setShowSuccessModal,
    setSuccessModalData,
    setIsClaimFarmStarted,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.imageWrapper}>
        <div
          className={clsx(
            styles.shineBig,
            isStarted ? styles.shineBigSmall : "",
          )}
        />
        <div
          className={clsx(
            styles.shineMid,
            isStarted ? styles.shineMidSmall : "",
          )}
        />
        <div
          className={clsx(
            styles.shineLow,
            isStarted ? styles.shineLowSmall : "",
          )}
        />
        <img
          src="/img/farm.avif"
          width="329"
          height="329"
          alt=""
          className={clsx(
            styles.image,
            isStarted && data.farmedReward ? styles.imageActive : "",
          )}
        />
      </div>

      <div className={styles.farmInfo} disabled={!isStarted}>
        <div className={styles.baseFarm}>
          <span className={styles.baseFarmLable}>
            <Text variant="textM" weight="bold">
              Base Farm
            </Text>
          </span>
          <Text variant="textXL" weight="bold">
            BASE FARM: {baseFarm} DRUMs / {farmDuration} Hours
          </Text>
          <Text variant="textXL" weight="bold">
            MAX Farm: {baseFarmPerDay} DRUMs / 24 Hours
          </Text>
        </div>
        <div className={styles.bonusFarm}>
          <span className={styles.bonusFarmLable}>
            <Text variant="textM" weight="bold">
              Bonus Farm
            </Text>
          </span>
          <Text variant="textXL" weight="bold" color="gradient">
            Bonus to BASE:{" "}
            {baseBonusFarm === "N/A" ? "N/A" : formatAmount(baseBonusFarm)}{" "}
            DRUMs / {farmDuration} Hours
          </Text>
          <Text variant="textXL" weight="bold" color="gradient">
            Bonus to MAX:{" "}
            {bonusFarmPerDay === "N/A" ? "N/A" : formatAmount(bonusFarmPerDay)}{" "}
            DRUMs / 24 Hours
          </Text>
        </div>
      </div>

      <div className={styles.farmDescription} disabled={isStarted}>
        <div className={styles.lvlInfo}>
          <Text variant="textL" weight="bold">
            {displayLvlInfo}
          </Text>
        </div>

        <Text variant="h2" weight="bold" tag="p" className={styles.text}>
          Farm{" "}
          <Text variant="h2" weight="bold" color="gradient">
            {TOKEN_NAME}
          </Text>{" "}
          tokens
        </Text>
        <div>
          <Text variant="textXL" weight="bold" tag="p" className={styles.text}>
            Boost your game with token farming!
            <br />
            Earn {TOKEN_NAME} and keep the farm rocking!
          </Text>
        </div>
      </div>

      {isStarted && data.farmedReward ? (
        <div className={styles.balanceContainer}>
          <Text variant="textL" weight="bold" color="green">
            Farming era reward
          </Text>
          <div className={styles.balance}>
            <Text variant="h1" weight="bold">
              {formatNumberForUi(data.farmedReward)}
            </Text>{" "}
            <ShineTokenName />
          </div>
        </div>
      ) : null}
      <div className={styles.buttonContainer}>
        {buttonState === "farming" ? (
          <Button variant="filledSec" disabled={true} className={styles.button}>
            <Text variant="textXL" weight="bold" color="green">
              Current farming era: {data.counter ?? "N/A"}
            </Text>
          </Button>
        ) : buttonState === "claim" ? (
          <Button
            variant="filledSec"
            onClick={handleClaimFarm}
            disabled={!data.isClaimAllowed}
            className={styles.button}
          >
            <Text variant="textXL" weight="bold">
              Claim
            </Text>
          </Button>
        ) : (
          <Button
            variant="filledSec"
            onClick={handleStartFarm}
            disabled={!!data.farmStartedAt}
            className={styles.button}
          >
            <Text variant="textXL" weight="bold">
              Start farming era
            </Text>
          </Button>
        )}
      </div>

      {message.show && (
        <div className={styles.popupMessage}>
          {
            <Text variant="textXM" weight="bold">
              {message.text}
            </Text>
          }
        </div>
      )}
    </div>
  );
};

export default React.memo(Farm);
